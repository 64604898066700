import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    this.swiper = new Swiper('.brand-image-slider__items', {
      loop: true,
      simulateTouch: true,
      centeredSlides: true,
      autoHeight: true,
      slideClass: 'brand-image-slider__item',
      wrapperClass: 'brand-image-slider__wrapper',
      slideActiveClass: 'brand-image-slider__item--active',
      slideVisibleClass: 'brand-image-slider__item--visible',
      slideDuplicateClass: 'brand-image-slider__item--duplicate',
      slideNextClass: 'brand-image-slider__item--next',
      slidePrevClass: 'brand-image-slider__item--prev',
      navigation: {
        nextEl: '.brand-image-slider__next',
        prevEl: '.brand-image-slider__prev',
        disabledClass: 'brand-image-slider__disabled-nav',
      },
      pagination: {
        el: '.brand-image-slider__pagination',
        type: 'bullets',
        clickable: true,
        }
    });
  },
}
