import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').on('click', '.notifications__close', this.onClose);
  },

  onClose() {
    const $item = $(this).closest('.notifications__item');
    const type = $item.data('type');
    const reference = $item.data('reference');

    $item.remove();
    $(document).trigger('updateHeaderNotificationsCount', $('.notifications__item:not(.notifications__item--read)').length);

    $.post('/RemoveNotification', {
      type,
      reference,
    });
  },

  markAllRead() {
    const $items = $('.notifications__item');

    if (!$items.length) {
      return;
    }

    const notifications = $items.map((i, el) => ({
      type: $(el).data('type'),
      reference: $(el).data('reference'),
    })).toArray();

    $items.addClass('.notifications__item--read');

    $.post('/MarkNotificationsAsRead', {
      notifications,
    });
  },
}
