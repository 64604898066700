import $ from 'jquery';
import ButtonSpinner from './button-spinner';
import { logIn, register } from './auth';
import { convertImgToSvg } from './dom';
import debounce from 'debounce';
import notifications from './notifications';

export default {
  init({ fixed }) {
    this.addEvents();
    this.modifyDOM();

    if (fixed) {
      this.setupFixedHeader();
    }
  },

  addEvents() {
    $('body')
      .on('mouseenter mouseleave', '.header-dropdown__section--buy', this.onMouseEventBuyDropdown.bind(this))
      .on('mouseenter mouseleave', '.header-dropdown__section--brands', this.onMouseEventBrandsDropdown.bind(this))
      .on('mouseenter', '#buy.site-header__nav__item', this.onBuyMouseenter.bind(this))
      .on('mouseenter', '#brands.site-header__nav__item', this.onBrandsMouseenter.bind(this))
      .on('mouseleave', '#buy.site-header__nav__item', this.onBuyMouseleave.bind(this))
      .on('mouseleave', '#brands.site-header__nav__item', this.onBrandsMouseleave.bind(this))
      .on('click', '.site-header__nav-icons__item--search', this.onShowSearchSection.bind(this))
      .on('click', '.site-header__nav-icons__item--login', this.onShowLoginSection.bind(this))
      .on('click', '.site-header__nav-icons__item--cart', this.onShowCartSection.bind(this))
      .on('click', '.site-header__nav-icons__item--notifications', this.onShowNotificationsSection.bind(this))
      .on('click', '.site-header__meta__nav__item--lang', this.onShowSitesSection.bind(this))
      .on('click', '.site-header__meta__nav__item--login', this.onShowLoginSection.bind(this))
      .on('click', '.site-header__debug-toggle', this.onShowDebugSection.bind(this))
      .on('submit', '.header-dropdown__section--login__form', this.onLoginSubmit)
      .on('submit', '.header-dropdown__section--login__register form', this.onRegisterSubmit)
      .on('click', '.header-backdrop', this.onHideSection.bind(this))
      .on('click', '.header-dropdown__close', this.onHideSection.bind(this));

    $(document).on('updateHeaderCartProductCount', this.onUpdateCartCount.bind(this));
    $(document).on('updateHeaderNotificationsCount', this.onUpdateNotificationsCount.bind(this));
  },

  modifyDOM() {
    $('.brand-list__item img').each(function () {
      convertImgToSvg(this);
    });
  },

  setupFixedHeader() {
    const $meta = $('.site-header__meta');
    const headerHeight = $('.site-header').outerHeight();
    const headerMetaHeight = $meta.length ? $('.site-header__meta').outerHeight() : 0;
    const handler = () => {
      this.onScroll(headerHeight, headerMetaHeight);
    };

    $(window).on('scroll', debounce(handler, 1));
    handler();
  },

  onScroll(headerHeight, headerMetaHeight) {
    const top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    if (top >= headerMetaHeight) {
      $('body').addClass('has-fixed-header');
      $('body').css('padding-top', headerHeight);
    } else {
      $('body').removeClass('has-fixed-header');
      $('body').css('padding-top', '');
    }
  },

  onMouseEventBuyDropdown(e) {
    $('#buy.site-header__nav__item').trigger(e.type);
  },

  onMouseEventBrandsDropdown(e) {
    $('#brands.site-header__nav__item').trigger(e.type);
  },

  onBuyMouseenter(e) {
    setTimeout(() => {
      $('.site-header__nav').addClass('is-hovering');
      $(e.currentTarget).addClass('is-hovering');
      this.showSection($('.header-dropdown__section--buy'));
    }, 1);
  },

  onBrandsMouseenter(e) {
    setTimeout(() => {
      $('.site-header__nav').addClass('is-hovering');
      $(e.currentTarget).addClass('is-hovering');
      this.showSection($('.header-dropdown__section--brands'));
    }, 1);
  },

  onBuyMouseleave() {
    setTimeout(() => {
      if ($('.header-dropdown:hover').length === 0) {
        this.hideAnySection();
      }
    }, 1);
  },

  onBrandsMouseleave() {
    setTimeout(() => {
      if ($('.header-dropdown:hover').length === 0) {
        this.hideAnySection();
      }
    }, 1);
  },

  onUpdateCartCount(e, count) {
    this.updateHeaderIconCount('cart', count);
  },

  onUpdateNotificationsCount(e, count) {
    this.updateHeaderIconCount('notifications', count);
  },

  updateHeaderIconCount(type, count) {
    const $el = $(`.site-header__nav-icons__item--${type}__count`);
    const $respEl = $(`.responsive-header__item--${type} .responsive-header__item__count`);
    const iconActiveClass = `site-header__nav-icons__item__icon--active`;
    const respIconActiveClass = `responsive-header__item__icon--active`;
    const iconSmClass = `site-header__nav-icons__item--${type}__count--sm`;
    const iconXsClass = `site-header__nav-icons__item--${type}__count--xs`;
    const respIconSmClass = `responsive-header__item__count--sm`;
    const respIconXsClass = `responsive-header__item__count--xs`;

    if (count === 0) {
      $el.text('');
      $respEl.text('');
    } else if (count > 99) {
      $el.text('99+');
      $respEl.text('99+');
    } else {
      $el.text(count);
      $respEl.text(count);
    }

    if (count > 0) {
      $el.parent().addClass(iconActiveClass);
      $respEl.parent().addClass(respIconActiveClass);
    } else {
      $el.parent().removeClass(iconActiveClass);
      $respEl.parent().removeClass(respIconActiveClass);
    }

    $el.removeClass(iconSmClass);
    $respEl.removeClass(respIconSmClass);
    $el.removeClass(iconXsClass);
    $respEl.removeClass(respIconXsClass);

    if (count > 99) {
      $el.addClass(iconXsClass);
      $respEl.addClass(respIconXsClass);
    } else if (count > 9) {
      $el.addClass(iconSmClass);
      $respEl.addClass(respIconSmClass);
    }
  },

  onLoginSubmit() {
    const $btn = $(this).find('[type="submit"]');
    const spinner = new ButtonSpinner($btn[0]);
    const redirectUrl = $btn.data('redirect-url');
    spinner.start();

    logIn($(this)).then(
      () => {
        spinner.stop();
        $('.header-dropdown__section--login__error').addClass('hidden');
        window.location.href = redirectUrl;
      },
      (xhr) => {
        spinner.stop();
        $('.header-dropdown__section--login__error').text(xhr.responseJSON.message).removeClass('hidden');
      }
    );

    return false;
  },

  onRegisterSubmit() {
    const $form = $(this);
    const $btn = $form.find('[type="submit"]');
    const spinner = new ButtonSpinner($btn[0]);
    const redirectUrl = $btn.data('redirect-url');
    const $error = $('.header-dropdown__section--login__register__error');
    const $password = $form.find('[name="password"]');
    const $confirmPassword = $form.find('[name="confirm-password"]');

    if ($password.val() !== $confirmPassword.val()) {
      $error.text($confirmPassword.data('invalid-message')).removeClass('hidden');
      return false;
    } else {
      $error.addClass('hidden');
    }

    spinner.start();

    register($form).then(
      xhr => {
        spinner.stop();
        $error.addClass('hidden');
        window.location.href = redirectUrl;
      },
      xhr => {
        spinner.stop();
        $error.text(xhr.responseJSON.message).removeClass('hidden');
      }
    );

    return false;
  },

  onHideSection() {
    this.hideAnySection();
  },

  onShowSitesSection(e) {
    e.preventDefault();

    const $section = $('.header-dropdown__section--sites');
    const wasVisible = this.sectionIsVisible($section);

    this.hideAnySection();

    if (!wasVisible) {
      this.showSection($section);
      $(e.currentTarget).addClass('is-active');
    }
  },

  onShowLoginSection(e) {
    e.preventDefault();

    const $section = $('.header-dropdown__section--login');
    const wasVisible = this.sectionIsVisible($section);

    this.hideAnySection();

    if (!wasVisible) {
      this.showSection($section);
      $(e.currentTarget).addClass('is-active');
    }
  },

  onShowSearchSection(e) {
    const $section = $('.header-dropdown__section--search');
    const wasVisible = this.sectionIsVisible($section);

    this.hideAnySection();

    if (!wasVisible) {
      this.showSection($section);
      $(e.currentTarget).addClass('is-active');
    }
  },

  onShowCartSection(e) {
    const wasVisible = $('.shopping-cart').hasClass('active');

    this.hideAnySection();

    if (!wasVisible) {
      $('.shopping-cart').css('top', $('.site-header').outerHeight());
      $('.shopping-cart').addClass('active');
      $(e.currentTarget).addClass('is-active');
    }
  },

  onShowNotificationsSection(e) {
    const wasVisible = $('.notifications').hasClass('active');

    this.hideAnySection();

    if (!wasVisible) {
      $('.notifications').css('top', $('.site-header').outerHeight());
      $('.notifications').addClass('active');
      $(e.currentTarget).addClass('is-active');
    }
  },

  onShowDebugSection() {
    this.showSection($('.header-dropdown__section--debug'));
  },

  showSection($section) {
    $('.header-dropdown').css('top', $('.site-header').outerHeight() - 1);
    $('.header-dropdown').addClass('header-dropdown--is-visible');
    $section.siblings('.header-dropdown__section--is-visible').removeClass('header-dropdown__section--is-visible');
    $section.addClass('header-dropdown__section--is-visible');
    $section.find('input').first().focus();
  },

  hideAnySection() {
    const notificationsWasActive = $('.notifications').hasClass('active');

    $('.header-dropdown__section--is-visible').removeClass('header-dropdown__section--is-visible');
    $('.site-header__nav.is-hovering, .site-header__nav__item.is-hovering').removeClass('is-hovering');
    $('.site-header__nav-icons__item.is-active').removeClass('is-active');
    $('.header-dropdown').removeClass('header-dropdown--is-visible');
    $('.shopping-cart, .notifications').removeClass('active');

    if (notificationsWasActive) {
      notifications.markAllRead();
    }
  },

  sectionIsVisible($section) {
    return $section.hasClass('header-dropdown__section--is-visible');
  },
}
