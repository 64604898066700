import $ from 'jquery';

export function scrollToDiv($el, speed = 700, placement = 'center') {
  const elOffset = $el.offset().top;
  const elHeight = $el.height();
  const windowHeight = $(window).height();
  let offset;

  if (placement === 'center') {
    if (elHeight < windowHeight) {
      offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
    } else {
      offset = elOffset;
    }
  } else if (placement === 'top') {
    offset = elOffset - 90;
  }


  $('html, body').animate({ scrollTop: offset }, speed);
}

export function inViewport(element, margin = 0) {
  let el = element;

  if (el.length) {
    el = el[0];
  }

  const rect = el.getBoundingClientRect();
  const yAxis = (rect.bottom + margin <= $(window).height());
  const xAxis = (rect.left < $(window).width());

  return (yAxis && xAxis);
}

export function getWidthPercentInt(el) {
  const parentWidth = el.parentNode.offsetWidth;
  const elWidth = el.offsetWidth;

  return Math.round((elWidth / parentWidth) * 100);
}

export function getTopPixelsInt(el) {
  const topPixels = el.style.top;

  if (!topPixels) {
    return 0;
  }

  return parseInt(topPixels, 10);
}

export function convertImgToSvg(img) {
  const svgExtension = '.svg';
  const $img = $(img);
  const src = $img.attr('src');
  const attributes = $img.prop('attributes');

  if (!src || src.indexOf(svgExtension, src.length - svgExtension.length) === -1) {
    return;
  }

  $.get(src, (data) => {
    // Get the SVG tag, ignore the rest
    let $svg = $(data).find('svg');

    // Remove any invalid XML tags
    $svg = $svg.removeAttr('xmlns:a');

    // Loop through IMG attributes and apply on SVG
    $.each(attributes, function () {
      $svg.attr(this.name, this.value);
    });

    // Replace IMG with SVG
    $img.replaceWith($svg);
  }, 'xml');
}

export default {
  scrollToDiv,
  inViewport,
  getWidthPercentInt,
  getTopPixelsInt
}
