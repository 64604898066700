import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper';
import SVG from 'svg.js';

const TIMELINE_DOT_DISTANCE = 300;
const SLIDE_SPEED = 650;

export default {
  init() {
    this.modifyDOM();
    this.initTimeline();
  },

  modifyDOM() {
    this.swiper = new Swiper('.timeline__cards', {
      loop: false,
      simulateTouch: true,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      autoHeight: true,
      slideClass: 'timeline__card',
      wrapperClass: 'timeline__card-wrapper',
      slideActiveClass: 'timeline__card--active',
      slideVisibleClass: 'timeline__card--visible',
      slideDuplicateClass: 'timeline__card--duplicate',
      slideNextClass: 'timeline__card--next',
      slidePrevClass: 'timeline__card--prev',
      navigation: {
        nextEl: '.timeline__card-nav--next',
        prevEl: '.timeline__card-nav--prev',
        disabledClass: 'timeline__card-nav--disabled',
      },
      on: {
        slideNextTransitionStart: this.moveTimelineForward.bind(this),
        slidePrevTransitionStart: this.moveTimelineBackwards.bind(this),
        slideChangeTransitionEnd: this.onSlideChangeTransitionEnd.bind(this),
        init: this.onInit.bind(this),
      }
    });
  },

  initTimeline() {

    const $mainPath = $('#timeline__main-path');

    if (!$mainPath.length) {
      return;
    }

    this.canvas = SVG('timeline__carousel__canvas');
    this.path = this.canvas.path($mainPath.attr('d'));
    this.path.fill('none').stroke({ width: 0 });
    this.pathLength = this.path.length();

    this.circles = [];
    const $cards = $('.timeline__card');
    $cards.each((idx, elem) => {
      const group = this.canvas.group();
      const circle = this.canvas.circle(25, 25);
      circle.fill('#085778');
      circle.dmove(10, 13);
      group.add(circle);
      const text = this.canvas.text($(elem).find('.timeline__card-timestamp').text());
      text.dmove(7, 40);
      group.add(text);
      const p = this.path.pointAt((this.pathLength / 2) + (-TIMELINE_DOT_DISTANCE * idx));
      this.canvas.add(group);
      group.center(p.x, p.y);
      this.circles.push(group);
    });

  },

  onInit() {
    const $active = $('.timeline__card--active');
    let height = 0;
    $('div', $active).each(function() {
      height += $(this).outerHeight();
    });
    $('.timeline__cards').css('height', height);
    $('.timeline').css('min-height', Math.max(height));
    if (!$('.timeline__card-image', $active).length) {
      $('.timeline__card-nav').css('top', '18px');
    } else {
      $('.timeline__card-nav').css('top', 'calc(140px + 1rem)');
    }
  },

  onSlideChangeTransitionEnd() {
    const $active = $('.timeline__card--active');
    let height = 0;
    $('div', $active).each(function() {
      height += $(this).outerHeight();
    });
    $('.timeline__cards').css('height', height);
    if (!$('.timeline__card-image', $active).length) {
      $('.timeline__card-nav').css('top', '18px');
    } else {
      $('.timeline__card-nav').css('top', 'calc(140px + 1rem)');
    }
  },

  moveTimelineForward() {
    this.circles.forEach((c, idx) => {
      c.animate(SLIDE_SPEED, '<>').during((pos, morph, eased) => {
        const val = ((this.pathLength / 2) -
          (TIMELINE_DOT_DISTANCE * idx) +
          ((this.swiper.realIndex - 1) * TIMELINE_DOT_DISTANCE) +
          (TIMELINE_DOT_DISTANCE * eased));
        var p = this.path.pointAt(val);
        c.center(p.x, p.y);
      });
    });
  },
  moveTimelineBackwards() {
    this.circles.forEach((c, idx) => {
      c.animate(SLIDE_SPEED, '<>').during((pos, morph, eased) => {
        const val = ((this.pathLength / 2) +
          (-TIMELINE_DOT_DISTANCE * idx + 1) +
          ((this.swiper.realIndex + 1) * TIMELINE_DOT_DISTANCE) +
          ((-TIMELINE_DOT_DISTANCE) * eased));
        var p = this.path.pointAt(val);
        c.center(p.x, p.y);
      });
    });
  }
}
