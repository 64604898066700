import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    this.swiper = new Swiper('.brand-list-slider__items', {
      loop: false,
      simulateTouch: true,
      slidesPerView: 'auto',
      slidesPerGroup: 2,
      slideClass: 'brand-list-slider__item',
      wrapperClass: 'brand-list-slider__wrapper',
      slideActiveClass: 'brand-list-slider__item--active',
      slideVisibleClass: 'brand-list-slider__item--visible',
      slideDuplicateClass: 'brand-list-slider__item--duplicate',
      slideNextClass: 'brand-list-slider__item--next',
      slidePrevClass: 'brand-list-slider__item--prev',
      navigation: {
        nextEl: '.brand-list-slider__next',
        prevEl: '.brand-list-slider__prev',
        disabledClass: 'brand-list-slider__disabled-nav',
      }
    });
  },
}
