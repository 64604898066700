import $ from 'jquery';
import CollapseComp from './collapse-comp';

export default {
  init() {
    this.collapses = [];
    this.modifyDOM();
  },

  modifyDOM() {
    $('.collapse').each((i, el) => {
      this.collapses.push(new CollapseComp($(el), {}));
    });
  },
};
