import $ from 'jquery';

import Header from '../components/header';
import BrandHeader from '../components/brand-header';
import ResponsiveNav from '../components/responsive-nav';
import CookieDisclaimer from '../components/cookie-disclaimer';
import BlockImageGrid from '../components/image-grid';
import BlockStory from '../components/block-story';
import SnusHistory from '../components/snus-history-page';
import BrandListSlider from '../components/brand-list-slider';
import BrandImageSlider from '../components/brand-image-slider';
import Collapse from '../components/collapse';
import FunFacts from '../components/fun-facts';
import Timeline from '../components/timeline';
import BrandCollapse from '../components/brand-collapse';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();

    let fixed = false;

    // TODO: check this karduspage check
    if ($('.site-header').is(':visible') && $('.page-karduspage').length === 0) {
      fixed = true;
    }

    if ($('.brand-site-header').is(':visible') && $('.page-karduspage').length === 0) {
      fixed = true;
    }

    // Generic
    CookieDisclaimer.init();
    Header.init({ fixed });
    BrandHeader.init({ fixed });
    ResponsiveNav.init();

    // EPi blocks
    BlockImageGrid.init();
    BlockStory.init();

    // Misc
    SnusHistory.init();
    BrandListSlider.init();
    BrandImageSlider.init();
    Collapse.init();
    BrandCollapse.init();
    FunFacts.init();
    Timeline.init();
  },

  addEvents() {
  },

  modifyDOM() {
    if (window.navigator.userAgent.indexOf('Trident/') > 0) {
      $('body').addClass('ie11');
    }

    // TODO: work this out
    $(`.block-cover-image img,
          .start-page__content-grid__item__image,
          .block-text-image__item__image,
          .block-colorfull-message__image,
          .block-image-columns__hero,
          .block-image-grid__item__image,
          .hero--blend-landing,
          .hero--startpage,
          .store-list__item__image,
          .block-side-widget__stores-list__item__image,
          .news-list__item__image,
          .blends,
          .blend-completed__image,
          .runners__grid__item__image,
          .news-feed__aside,
          .event__hero,
          .block-promo__image`).lazyload();
  },
};
