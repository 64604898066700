import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(window).on('scroll', this.toggleHeaderAction);
    $('body').on('click', '.hero__action--play', this.onPlayVideo);
    $('body').on('click', '.hero__action--cancel', this.onCloseVideo);
    $('body').on('click', '.site-wrapper', this.onAnyClick.bind(this));

    $('.hero').lazyload({
      load() {
        $(this).trigger('lazyloaded');
      }
    });
  },

  onAnyClick() {
    if ($('.hero').hasClass('hero--video-playing')) {
      this.onCloseVideo();
    }
  },

  toggleHeaderAction() {
    if ($(this).scrollTop() > 100) {
      $('.hero__action--scroll').addClass('out');
    } else {
      $('.hero__action--scroll').removeClass('out').fadeIn();
    }
  },

  onPlayVideo() {
    $('.hero').addClass('hero--video-playing');
    const videoIframe = $('.hero__video').find('iframe');
    const videoURL = videoIframe.data('src');
    videoIframe.attr('src', videoURL + '?autoplay=true');
    return false;
  },

  onCloseVideo() {
    $('.hero').removeClass('hero--video-playing');
    const videoIframe = $('.hero__video').find('iframe');
    videoIframe.attr('src', '');
  },
}
