import $ from 'jquery';
import { Spinner as SpinJS } from '../spin.umd.js';
import defaultOpts from './spinner-opts';

export default class Spinner {
  constructor(el, opts = {}) {
    this.el = el;
    this.$el = $(el);
    this.opts = {
      ...defaultOpts,
      ...opts,
    };
    this.spinner = new SpinJS(this.opts);
  }

  start() {
    this.spinner.spin();
    this.el.appendChild(this.spinner.el);
    this.$el.addClass('is-spinning');
  }

  stop() {
    this.spinner.stop();
    this.$el.removeClass('is-spinning');
  }
}
