import $ from 'jquery';
import Cookie from 'js-cookie';

export default {
  init() {
    this.modifyDOM();
    this.addEvents();
  },

  modifyDOM() {
    if (!Cookie.get('cookiesAccepted')) {
      $('.cookie-disclaimer').removeClass('u-hidden');
    }
  },

  addEvents() {
    $('.js-cookie-disclaimer-accept').on('click', this.onClick);
  },

  onClick() {
    Cookie.set('cookiesAccepted', true, { expires: 365 });
    $('.cookie-disclaimer').remove();
  },
}
