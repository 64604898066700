import $ from 'jquery';
import Vivus from 'vivus';
import Dom from './dom'
import debounce from 'debounce';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('scroll', debounce(this.onScroll.bind(this), 1));
  },

  onScroll() {
    $('.fun-facts__item:not(.fun-facts__item--inviewport)').each((i, el) => {
      if (Dom.inViewport(el, -300)) {
        this.handleInViewport(el);
      }
    });
  },

  handleInViewport(el) {
    const $el = $(el);
    $el.addClass('fun-facts__item--inviewport');
    new Vivus($el.find('.fun-facts__gfx object')[0]), {
      duration: 400,
      animTimingFunction: Vivus.EASE
    }
  },
}
