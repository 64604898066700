import $ from 'jquery';

export function logIn($form) {
  const credentials = {};

  $form.serializeArray().forEach((field) => {
    let value = field.value;

    if (field.name === 'rememberMe' && field.value === 'on') {
      value = true;
    }

    credentials[field.name] = value;
  });

  return $.post('/api/user/login', credentials);
}

export function register($form) {
  const credentials = {};

  $form.serializeArray().forEach(field => {
    if (field.name === 'confirm-password') {
      return;
    }

    credentials[field.name] = field.value;
  });

  return $.post('/api/user/register', credentials);
}

export default {
  logIn,
  register
}
