import $ from 'jquery';
import { inViewport } from './dom';
import debounce from 'debounce';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('scroll', debounce(this.onScroll.bind(this), 1));
  },

  onScroll() {
    $('.block-story__item:not(.block-story__item--inviewport)').each((i, el) => {
      if (inViewport(el, -300)) {
        this.handleInViewport(el);
      }
    });
  },

  handleInViewport(el) {
    const $el = $(el);
    $el.addClass('block-story__item--inviewport');
  },
}
