import $ from 'jquery';
import ButtonSpinner from './button-spinner';
import { logIn } from './auth';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $('body')
      .on('click', '.responsive-header__item--cart', this.onCartClick.bind(this))
      .on('click', '.responsive-header__item--notifications', this.onNotificationsClick.bind(this))
      .on('click', '.responsive-nav-toggle', this.onNavClick.bind(this))
      .on('click', '.responsive-nav__primary__item__toggle-icon', this.showMobileSubMenu)
      .on('submit', '.responsive-nav-login', this.onLoginSubmit)
      .on('click', '.responsive-nav__primary__item--login', this.onLoginClick)
      .on('click', '.responsive-header--search', this.onSearchClick)
      .on('click', '.header-backdrop', this.onBackdropClick.bind(this));
  },

  modifyDOM() {
    if ($('.responsive-header').is(':visible')) {
      $('body').css('padding-top', $('.responsive-header').outerHeight());
    }
  },

  onBackdropClick() {
    this.closeAll();
  },

  onCartClick() {
    if ($('.shopping-cart').hasClass('active')) {
      this.closeAll();
    } else {
      this.closeAll();
      this.openCart();
    }
  },

  onNotificationsClick() {
    if ($('.notifications').hasClass('active')) {
      this.closeAll();
    } else {
      this.closeAll();
      this.openNotifications();
    }
  },

  onNavClick() {
    if ($('.responsive-nav').hasClass('active') || $('.responsive-nav-search').hasClass('active')) {
      this.closeAll();
    } else {
      this.closeAll();
      this.openNav();
    }
  },

  onSearchClick() {
    $('.responsive-nav-toggle').addClass('active');
    $('.responsive-header--search').addClass('active');
    $('.responsive-nav-search').addClass('active');
    $('body').addClass('responsive-nav-open');
  },

  openNav() {
    $('.responsive-nav-toggle').addClass('active');
    $('.responsive-nav').addClass('active');
    $('body').addClass('responsive-nav-open');
  },

  openCart() {
    $('.responsive-header__item--cart').addClass('active');
    $('.shopping-cart').addClass('active');
    $('body').addClass('responsive-cart-open');
    $('.shopping-cart').css('top', $('.responsive-header').outerHeight());
  },

  openNotifications() {
    $('.responsive-header__item--notifications').addClass('active');
    $('.notifications').addClass('active');
    $('body').addClass('responsive-notifications-open');
    $('.notifications').css('top', $('.responsive-header').outerHeight());
  },

  closeAll() {
    $('.responsive-nav-toggle, .responsive-header__item').removeClass('active');
    $('.responsive-nav, .shopping-cart, .notifications').removeClass('active');
    $('.shopping-cart__secondary-actions__copy').removeClass('active');
    $('.site-header__nav-icons__item').removeClass('is-active');
    $('.responsive-header--search').removeClass('active');
    $('.responsive-nav-search').removeClass('active');
    $('body').removeClass('responsive-nav-open responsive-cart-open responsive-notifications-open');
  },

  showMobileSubMenu() {
    $(this).parent().toggleClass('collapsed').find('ul').first().toggle();
    return false;
  },

  onLoginClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.href.indexOf('#') !== -1) {
      $(this).toggleClass('collapsed').find('ul').first().toggle();
      return false;
    }

    return null;
  },

  onLoginSubmit() {
    const $form = $(this);
    const spinner = new ButtonSpinner($form.find('[type="submit"]')[0]);
    spinner.start();

    logIn($form).then(
      () => {
        spinner.stop();
        $form.find('.form-group--error').addClass('hidden');
        window.location.reload();
      },
      (xhr) => {
        spinner.stop();
        $form.find('.form-group--error').text(xhr.responseJSON.message).removeClass('hidden');
      }
    );

    return false;
  },
}
