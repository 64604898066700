import '../jquery-global';
import 'jquery-scrollspy/jquery-scrollspy';
import 'jquery-lazyload';
import 'jquery-unveil';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import '../modernizr';
import '../fotorama';
import Auth from '../components/auth';
import BlockImageGrid from '../components/image-grid';
import Spinner from '../components/spinner';
import ButtonSpinner from '../components/button-spinner';
import Collapse from '../components/collapse-comp';
import CookieDisclaimer from '../components/cookie-disclaimer';
import Dom from '../components/dom';
import Header from '../components/header';
import BrandHeader from '../components/brand-header';
import Hero from '../components/hero';
import Modal from '../components/modal';
import Notifications from '../components/notifications';
import ResponsiveNav from '../components/responsive-nav';
import common from './common';
import distributors from './distributors';
import BrandCollapse from '../components/brand-collapse-comp';

export default {
  common,
  distributorspage: distributors,
  Auth,
  BlockImageGrid,
  Spinner,
  ButtonSpinner,
  Collapse,
  BrandCollapse,
  CookieDisclaimer,
  Dom,
  Header,
  BrandHeader,
  Hero,
  Modal,
  Notifications,
  ResponsiveNav,
};
