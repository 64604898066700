import $ from 'jquery';
import Modal from './modal';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').on('click', '.block-image-grid__item__image', this.onImageClick.bind(this));
  },

  onImageClick(e) {
    this.openImageModal($(e.target).data('fullsize'));
  },

  openImageModal(imageUrl) {
    const opts = {
      content: '<img src="' + imageUrl + '"/>',
      closeOnModalClick: true,
      classes: 'image-modal',
      backdropClasses: 'image-modal',
    };

    this.modal = new Modal(opts);
    this.modal.open();
  }
}
