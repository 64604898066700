import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body')
      .on('change', '.find-distributors__header-col select', this.onMarketChanged.bind(this))
      .on('click', '.find-distributors__header-show-desc', this.onShowDesc)
      .on('click', '.find-distributors__header-hide-desc', this.onHideDesc);
  },

  onMarketChanged({ currentTarget }) {
    const $select = $(currentTarget);
    const market = $select.val();

    $.ajax({
      type: 'GET',
      url: 'distributors?market=' + market,
      success: function (data) {
        $('.find-distributors__grid').closest('.container').html(data);
      }
    });
  },

  onShowDesc() {
    console.log('showdesc');
    $('.find-distributors__header-desc').addClass('active');
    $('.find-distributors__header-show-desc, .find-distributors__header-hide-desc').toggleClass('active');
  },

  onHideDesc() {
    console.log('hidedesc');
    $('.find-distributors__header-desc').removeClass('active');
    $('.find-distributors__header-show-desc, .find-distributors__header-hide-desc').toggleClass('active');
  },
};
