import $ from 'jquery';
import BrandCollapseComp from './brand-collapse-comp';

export default {
  init() {
    this.collapses = [];
    this.modifyDOM();
  },

  modifyDOM() {
    $('.brand-collapse').each((i, el) => {
      this.collapses.push(new BrandCollapseComp($(el), {}));
    });
  },
};
