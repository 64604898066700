import $ from 'jquery';

export default class Modal {
  constructor(opts = {}) {
    const defaultOpts = {
      content: '',
      classes: '',
      backdropClasses: '',
      closeOnBackdropClick: true,
      closeOnModalClick: false,
      displayClose: false,
      displayBlur: false,
      onClose: () => { },
    };

    this.opts = {
      ...defaultOpts,
      ...opts,
    };
    this.$body = $('body');
    this.$modal = $(`<div class="modal ${this.opts.classes}">${this.opts.content}</div>`);
    this.$backdrop = $(`<div class="modal-backdrop ${this.opts.backdropClasses}"></div>`);

    if (this.opts.closeOnBackdropClick) {
      this.$backdrop.on('click', this.close.bind(this));
    }

    if (this.opts.closeOnModalClick) {
      this.$modal.on('click', this.close.bind(this));
    }

    if (this.opts.displayClose) {
      this.$close = $('<i class="modal__close"></i>');
      this.$close.on('click', this.close.bind(this));
    }
  }

  open() {
    if (this.opts.displayBlur) {
      this.$body.addClass('modal-blur');
    }

    if (this.opts.displayClose) {
      this.$modal.append(this.$close);
    }

    this.$body.addClass('modal-open');
    this.$body.append(this.$backdrop);
    this.$body.append(this.$modal);
  }

  close() {
    this.opts.onClose();
    this.$body.removeClass('modal-open');
    this.$modal.remove();
    this.$backdrop.remove();

    if (this.opts.displayBlur) {
      this.$body.removeClass('modal-blur');
    }
  }
}
