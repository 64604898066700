import Spinner from './spinner';
import defaultOpts from './spinner-opts-button';

export default class ButtonSpinner extends Spinner {
  constructor(el, opts = {}) {
    const spinnerOpts = {
      ...defaultOpts,
      ...opts,
    };
    super(el, spinnerOpts);
  }

  start() {
    super.start();
    this.$el.css('color', this.opts.textColor);
  }

  stop() {
    super.stop();
    this.$el.css('color', '');
  }
}
