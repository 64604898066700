import $ from 'jquery';
import { convertImgToSvg } from './dom';
import debounce from 'debounce';

export default {
  init({ fixed }) {
    this.addEvents();
    this.modifyDOM();

    if (fixed) {
      this.setupFixedHeader();
    }
  },

  addEvents() {
    $('body')
      .on('click', '.brand-site-header__nav-icons__item--search', this.onShowSearchSection.bind(this))
      .on('click', '.brand-site-header__nav-icons__item--notifications', this.onShowNotificationsSection.bind(this))
      .on('click', '.brand-site-header__meta__nav__item--lang', this.onShowSitesSection.bind(this))
      .on('click', '.brand-header-backdrop', this.onHideSection.bind(this))
      .on('click', '.brand-site-header__search--close', this.onCloseSearch.bind(this))
      .on('click', '.brand-header-dropdown__close', this.onHideSection.bind(this));

    $(document).on('updateHeaderNotificationsCount', this.onUpdateNotificationsCount.bind(this));
  },

  modifyDOM() {
    $('.brand-list__item img').each(function () {
      convertImgToSvg(this);
    });
  },

  setupFixedHeader() {
    const $meta = $('.brand-site-header__meta');
    const headerHeight = $('.brand-site-header').outerHeight();
    const headerMetaHeight = $meta.length ? $('.brand-site-header__meta').outerHeight() : 0;
    const handler = () => {
      this.onScroll(headerHeight, headerMetaHeight);
    };

    $(window).on('scroll', debounce(handler, 1));
    handler();
  },

  onScroll(headerHeight, headerMetaHeight) {
    const top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    if (top >= headerMetaHeight) {
      $('body').addClass('has-fixed-header');
    } else {
      $('body').removeClass('has-fixed-header');
    }
  },

  onUpdateNotificationsCount(e, count) {
    this.updateHeaderIconCount('notifications', count);
  },

  onHideSection() {
    this.hideAnySection();
  },

  onShowSitesSection(e) {
    e.preventDefault();

    const $section = $('.brand-header-dropdown__section--sites');
    const wasVisible = this.sectionIsVisible($section);

    this.hideAnySection();

    if (!wasVisible) {
      this.showSection($section);
      $(e.currentTarget).addClass('is-active');
    }
  },

  onShowSearchSection(e) {
    const $section = $('.brand-site-header__search');
    const $header = $('.brand-site-header__content');
    const wasVisible = this.sectionIsVisible($section);

    this.hideAnySection();

    if (!wasVisible) {
      this.showSection($section);
      this.hideSection($header);
      $(e.currentTarget).addClass('is-active');
    }
  },

  onCloseSearch(e) {
    const $header = $('.brand-site-header__content');

    $header.removeClass('hide');

    this.hideAnySection();
  },

  onShowNotificationsSection(e) {
    const wasVisible = $('.notifications').hasClass('active');

    this.hideAnySection();

    if (!wasVisible) {
      $('.notifications').css('top', $('.brand-site-header').outerHeight());
      $('.notifications').addClass('active');
      $(e.currentTarget).addClass('is-active');
    }
  },

  showSection($section) {
    $('.brand-header-dropdown').css('top', $('.brand-site-header').outerHeight() - 1);
    $('.brand-header-dropdown').addClass('header-dropdown--is-visible');
    $section.siblings('.brand-header-dropdown__section--is-visible').removeClass('brand-header-dropdown__section--is-visible');
    $section.addClass('brand-header-dropdown__section--is-visible');
    $section.find('input').first().focus();
  },

  hideAnySection() {
    $('.brand-header-dropdown__section--is-visible').removeClass('brand-header-dropdown__section--is-visible');
    $('.brand-site-header__nav.is-hovering, .brand-site-header__nav__item.is-hovering').removeClass('is-hovering');
    $('.brand-site-header__nav-icons__item.is-active').removeClass('is-active');
    $('.brand-header-dropdown').removeClass('brand-header-dropdown--is-visible');
  },

  hideSection($section) {
    $section.addClass('hide');
  },

  sectionIsVisible($section) {
    return $section.hasClass('brand-header-dropdown__section--is-visible');
  },
}
