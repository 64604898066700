import $ from 'jquery';
import * as Lethargy from 'lethargy';

export default {
  init() {
    if (!$('.snus-history').length) {
      return;
    }
    this.lethargy = new Lethargy.default.Lethargy();
    this.navBaseClass = 'snus-history__navigation__list__item';
    this.navActiveClass = 'snus-history__navigation__list__item--current';
    this.itemBaseClass = 'snus-history__item';
    this.itemActiveClass = 'snus-history__item--current';
    this.totalItems = $('.' + this.navBaseClass).length;
    this.hasScrolledDown = false;
    this.currentStep = -1;
    this.touchY = undefined;
    this.disableScroll = false;

    this.modifyDOM();
    this.addEvents();
  },

  modifyDOM() {
    $(document).ready(() => {
      $('.snus-history').addClass('sh-loaded');
    });

    $('.hero').one('lazyloaded', () => {
      this.applyLazyload(0);
    });
  },

  addEvents() {
    $('body')
      .on('click', '.' + this.navBaseClass, this.onItemClick.bind(this));

    const lethargyCb = this.onScroll.bind(this);

    if ($(window).width() > 820) {

      window.addEventListener("wheel", e => {
        if (this.contentIsOverflowing() && !this.contentIsBottom()) {
          return;
        }

        const result = this.lethargy.check(e);

        e.preventDefault();
        e.stopPropagation();

        if (result !== false) {
          lethargyCb(result);
        }
      }, { passive: false });

      $(window)
        .on('touchstart', this.onTouchstart.bind(this))
        .on('touchend', this.onTouchend.bind(this));
    }

    $(document)
      .on('click', '.hero .hero__button', this.onClickStart.bind(this))
      .on('click', '.snus-history__item__content__actions .next', this.onClickNext.bind(this))
      .on('click', '.snus-history__item__content__actions .previous', this.onClickPrevious.bind(this))
      .on('keydown', this.onKeydown.bind(this));
  },

  onTouchstart(e) {
    this.touchY = e.originalEvent.touches[0].clientY;
  },

  onTouchend(e) {
    const touchYend = e.originalEvent.changedTouches[0].clientY;
    let result;

    if (this.touchY > touchYend + 5) {
      result = -1;
    } else if (this.touchY < touchYend - 5) {
      result = 1;
    } else {
      return;
    }

    if (this.contentIsOverflowing()) {
      if (result === -1 && !this.contentIsBottom()) {
        return;
      }

      if (result === 1 && !this.contentIsTop()) {
        return;
      }
    }

    if (result === 1) {
      this.handleGoToPrev();
    } else if (result === -1) {
      this.handleGoToNext();
    }
  },

  onClickStart() {
    this.handleGoToNext();
  },

  onClickNext() {
    this.handleGoToNext();
  },

  onClickPrevious() {
    this.handleGoToPrev();
  },

  onKeydown(e) {
    if (e.keyCode === 38) {
      this.handleGoToPrev();
    } else if (e.keyCode === 40) {
      this.handleGoToNext();
    }
  },

  onItemClick(e) {
    this.goToStep($(e.currentTarget).index());
  },

  onScroll(e) {
    if (this.disableScroll) {
      return;
    }

    this.disableScroll = true;

    setTimeout(() => {
      this.disableScroll = false;
    }, 1500);

    if (e === -1) {
      this.handleGoToNext();
    }

    if (e === 1) {
      this.handleGoToPrev();
    }
  },

  contentIsOverflowing() {
    const $content = $('.' + this.itemActiveClass).find('.snus-history__item__content');

    if ($content.length === 0) {
      return false;
    }

    const contentHeight = Math.round($content.outerHeight());

    return $content[0].scrollHeight > contentHeight;
  },

  contentIsBottom() {
    const $content = $('.' + this.itemActiveClass).find('.snus-history__item__content');

    if ($content.length === 0) {
      return false;
    }

    const contentHeight = Math.round($content.outerHeight());

    return $content[0].scrollHeight <= contentHeight + $content[0].scrollTop;
  },

  contentIsTop() {
    const $content = $('.' + this.itemActiveClass).find('.snus-history__item__content');

    if ($content.length === 0) {
      return false;
    }

    return $content[0].scrollTop === 0;
  },

  handleGoToPrev() {
    if (this.currentStep === 0) {
      this.hasScrolledDown = false;
      this.stop();
    } else {
      this.goToPrevious();
    }
  },

  handleGoToNext() {
    if (this.hasScrolledDown) {
      this.goToNext();
    } else {
      this.hasScrolledDown = true;
      this.start();
    }
  },

  start() {
    $('.hero').addClass('sh-hidden');
    $('.snus-history__backdrop').addClass('sh-hidden');

    setTimeout(() => {
      $('.snus-history__backdrop').addClass('sh-transitionend');
      this.goToStep(0);
    }, 100);
  },

  stop() {
    $('.hero').removeClass('sh-hidden');
    $('.snus-history__backdrop').removeClass('sh-hidden sh-transitionend');
    $('.sh-transition-in').removeClass('sh-transition-in');
    this.currentStep = -1;
    this.setBarHeight();
  },

  goToStep(step) {
    if (this.totalItems < step + 1 || step + 1 < 1) {
      return;
    }

    window.scrollTo(0, 0);
    this.currentStep = step;

    // Special case for first item
    if (step === 0) {
      $('.' + this.itemBaseClass).eq(step).removeClass('sh-fade-out');
      $('.' + this.itemBaseClass).eq(step).addClass(this.itemActiveClass);
    }

    this.setBarHeight();

    setTimeout(() => {
      // Slide out
      $('.' + this.itemActiveClass).removeClass('sh-transition-in');

      setTimeout(() => {
        // Highlight nav item
        $('.' + this.navActiveClass).removeClass(this.navActiveClass);
        $('.' + this.navBaseClass).eq(step).addClass(this.navActiveClass);

        // Fade out/in items
        const $prevActive = $('.' + this.itemActiveClass);
        $prevActive.addClass('sh-fade-out').removeClass(this.itemActiveClass);
        $('.' + this.itemBaseClass).eq(step).removeClass('sh-fade-out').addClass(this.itemActiveClass);

        const $activeContent = $('.' + this.itemActiveClass).find('.snus-history__item__content');

        if ($(window).width() > 820 && this.contentIsOverflowing()) {
          $activeContent.addClass('is-overflowing');
        } else {
          $activeContent.removeClass('is-overflowing');
        }

        setTimeout(() => {
          // Slide in
          $('.' + this.itemBaseClass).eq(step).addClass('sh-transition-in');

          setTimeout(() => {
            // Hide prev
            $prevActive.removeClass('sh-fade-out');

            // Safari needs a reflow here...
            this.triggerReflow($('.' + this.itemActiveClass).find('.snus-history__item__content__text')[0]);
          }, 500);
        }, 500);
      }, 500);
    }, 500);
  },

  goToNext() {
    this.goToStep(this.currentStep + 1);
  },

  goToPrevious() {
    this.goToStep(this.currentStep - 1);
  },

  setBarHeight() {
    let barHeight = 0;

    if (this.hasScrolledDown) {
      if (this.totalItems === this.currentStep + 1) {
        barHeight = 100;
      } else {
        const itemPercent = 100 / this.totalItems;
        barHeight = (itemPercent * (this.currentStep + 1)) - (itemPercent / 2);
      }
    }

    setTimeout(() => {
      $('.snus-history__navigation-container').addClass('sh-transition-in');

      setTimeout(() => {
        $('.snus-history__navigation__bar--active').css('height', barHeight + '%');

        setTimeout(() => {
          $('.snus-history__navigation-container').removeClass('sh-transition-in').addClass('sh-transition-out');

          setTimeout(() => {
            $('.snus-history__navigation-container').removeClass('sh-transition-out');
          }, 500);
        }, 1000);
      }, 500);
    }, 200);
  },

  applyLazyload(index) {
    const $el = $('.snus-history__item__image').eq(index);
    let callback;

    if (index + 1 < $('.snus-history__item__image').length) {
      callback = () => {
        this.applyLazyload(index + 1);
      };
    }

    window.jQuery($el).lazyload({
      load: callback,
    });
  },

  triggerReflow(el) {
    setTimeout(() => {
      /*eslint-disable */
      el.style.display = 'none';
      el.offsetHeight;
      el.style.display = '';
      /*eslint-enable */
    }, 1);
  },
};
