export default class BrandCollapseComp {
  constructor($el, opts = {}) {
    const defaultOpts = {
      transitionDuration: 220,
      active: false,
    };

    this.opts = {
      ...defaultOpts,
      ...opts,
    };
    this.el = $el[0];
    this.$el = $el;
    this.$content = $el.find('.brand-collapse__content');
    this.activeClass = 'brand-collapse--active';

    if (this.opts.active) {
      this.show();
    }

    $el.on('click', '.brand-collapse__anchor', this.onAnchorClick.bind(this));
  }

  onAnchorClick() {
    this.toggle();
    return false;
  }

  toggle() {
    if (this.active) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.active = true;
    this.$el.addClass(this.activeClass);
    this.$content.slideDown(this.opts.transitionDuration);
  }

  hide() {
    this.active = false;
    this.$el.removeClass(this.activeClass);
    this.$content.slideUp(this.opts.transitionDuration);
  }
}
